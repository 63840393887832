<template>
  <div class="customers p-8">
    <div class="mx-auto max-w-5xl space-y-6">
      <div class="flex justify-between">
        <h1 class="text-3xl font-semibold ">
          Customers
        </h1>
        <router-link
          to="/customers/add"
          class="hover-trigger flex align-middle gap-1 text-blue-600 hover:text-blue-700"
        >
          <span class="hover-target pt-1 font-medium">Add</span>
          <svg
            class="h-7 w-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </router-link>
      </div>

      <div v-if="isLoading" class="flex justify-center gap-1">
        <div class="spinner dark"></div>
        <div>Loading</div>
      </div>

      <div>
        <div class="flex gap-3 justify-end mb-1 items-end">
          <label for="customer_search" class="text-gray-500 text-sm">
            Search:
          </label>
          <input
            v-model="search"
            type="text"
            name="customer_search"
            class="px-2 py-0 min-w-64 border-0 border-b border-gray-200 text-gray-500 focus:outline-none focus:ring-transparent"
          />
        </div>

        <div class="shadow overflow-hidden border border-gray-200">
          <table class="w-full divide-y divide-gray-200" id="customer-table">
            <thead class="bg-gray-50">
              <tr>
                <th
                  v-show="false"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  ID
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Business Name
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Phone
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Email
                </th>

                <th
                  v-show="false"
                  scope="col"
                  class="px-6 py-3 min-w-96 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Notes
                </th>

                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Customer Type
                </th>
                <th
                  v-show="false"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Billing Phone
                </th>
                <th
                  v-show="false"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Billing Address 1
                </th>
                <th
                  v-show="false"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Billing Address 2
                </th>
                <th
                  v-show="false"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Billing City
                </th>
                <th
                  v-show="false"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Billing State
                </th>
                <th
                  v-show="false"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Billing Zip
                </th>
                <th
                  v-show="false"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Locations
                </th>
                <th
                  v-show="false"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Quotes
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr
                v-for="customer in filteredCustomers"
                :key="customer.id"
                @click="goTo(`/customers/${customer.id}`)"
                class="hover:bg-gray-50"
              >
                <td
                  v-show="false"
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 align-top"
                >
                  {{ customer.id }}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                >
                  {{ customer.billing_business }}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                >
                  {{ customer.customer_name }}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                >
                  {{ customer.customer_phone }}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                >
                  {{ customer.customer_email }}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                >
                  {{ customerTypeFromId(customer?.customer_type) }}
                </td>
                <td
                  v-show="false"
                  class="px-6 py-4 text-sm text-gray-500 align-top whitespace-pre-wrap"
                >
                  {{ customer.customer_notes }}
                </td>

                <td
                  v-show="false"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                >
                  {{ customer.billing_phone }}
                </td>
                <td
                  v-show="false"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                >
                  {{ customer.billing_address1 }}
                </td>
                <td
                  v-show="false"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                >
                  {{ customer.billing_address2 }}
                </td>
                <td
                  v-show="false"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                >
                  {{ customer.billing_city }}
                </td>
                <td
                  v-show="false"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                >
                  {{ customer.billing_state }}
                </td>
                <td
                  v-show="false"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                >
                  {{ customer.billing_zip }}
                </td>
                <td
                  v-show="false"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                >
                  <ul>
                    <li
                      v-for="location in customer.locations"
                      :key="location.id"
                    >
                      {{ location }}
                    </li>
                  </ul>
                </td>
                <td
                  v-show="false"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                >
                  <ul>
                    <li v-for="quote in customer.quotes" :key="quote.id">
                      {{ quote }}
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <button
        v-show="false"
        @click="downloadCSV()"
        class="relative bg-blue-600 text-white text-lg font-medium px-4 mt-2 rounded py-2 flex justify-center gap-2"
      >
        Export IIF
      </button>
    </div>
  </div>
</template>

<script>
import { computed, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import getCollection from "@/composables/getCollection";

export default {
  props: ["user"],
  setup(props) {
    const isLoading = ref(true);
    const router = useRouter();
    const search = ref(null);

    const business_id = props.user.claims.business_id ?? props.id;

    const { error, documents: customers } = getCollection(
      `businesses/${business_id}/customers`
    );

    const {
      error: customertypes_err,
      documents: customertypes,
    } = getCollection(`businesses/${business_id}/customer_types`);

    const filteredCustomers = computed(() => {
      if (search.value) {
        return customers.value.filter(
          (c) =>
            c.customer_name
              .toLowerCase()
              .includes(search.value.toLowerCase()) ||
            c.customer_phone
              .replace(/[^0-9]/g, "")
              .replace(/^1+/g, "")
              .includes(search.value) ||
            c.customer_email
              .toLowerCase()
              .includes(search.value.toLowerCase()) ||
            c.billing_business
              ?.toLowerCase()
              .includes(search.value.toLowerCase())
        );
      }
      return customers.value;
    });

    const goTo = (url) => {
      router.push(url);
    };

    watchEffect(() => {
      if (customers.value) {
        isLoading.value = false;
      }
    });

    const customerTypeFromId = (customertype_id) => {
      if (customertypes?.value) {
        if (!customertype_id) return;
        return customertypes.value?.find(({ id }) => id === customertype_id)
          .customertype_name;
      }
    };

    const downloadCSV = () => {
      const table_id = "customer-table";
      let rows = document.querySelectorAll("table#" + table_id + " tr");
      // Construct csv
      let csv = [];
      let separator = ",";
      for (let i = 0; i < rows.length; i++) {
        let row = [],
          cols = rows[i].querySelectorAll("td, th");
        for (let j = 0; j < cols.length; j++) {
          let data = cols[j].innerText
            .replace(/(\r\n|\n|\r)/gm, "")
            .replace(/(\s\s)/gm, " ");
          data = data.replace(/"/g, '""');

          row.push('"' + data + '"');
        }
        csv.push(row.join(separator));
      }
      var csv_string = csv.join("\n");
      // Download it
      var filename =
        "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
      var link = document.createElement("a");
      link.style.display = "none";
      link.setAttribute("target", "_blank");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
      );
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return {
      isLoading,
      search,
      error,
      customertypes_err,
      customerTypeFromId,
      customers,
      filteredCustomers,
      goTo,
      downloadCSV,
    };
  },
};
</script>
